import { Routes } from '@angular/router';
import { lazyLoadError } from '@pixels/client/router/lazy-load-error';
import { ProblemType } from './feature/math-page/math.model';

export const MathRoutes = {
  [ProblemType.AddHourAndMinute]: { path: 'math/add-hour', label: '시간 덧셈' },
  [ProblemType.SubHourAndMinute]: { path: 'math/sub-hour', label: '시간 뺄셈' },
  [ProblemType.Add3Number]: { path: 'math/add-3number', label: '3자리수 덧셈' },
  [ProblemType.Sub3Number]: { path: 'math/sub-3number', label: '3자리수 뺄셈' },
  [ProblemType.Multiply1Number]: { path: 'math/mul-1number', label: '1자리수 곱셈' },
};

export const rootPagePosts = [
  { path: 'about-me', label: 'AboutMe' },
  { path: 'coding-activity', label: 'CodingActivity' },
  { path: 'study-2024', label: 'Study 2024' },
  { path: 'math', label: '초등수학' },
];
export const appRoutes: Routes = [
  { path: 'math', loadComponent: () => import('./feature/math/math.component').then((m) => m.MathComponent).catch(lazyLoadError) },
  ...Object.entries(MathRoutes).map(([key, route]) => ({ path: route.path, data: { type: key }, loadComponent: () => import('./feature/math-page/math-page.component').then((m) => m.MathPageComponent).catch(lazyLoadError) })),
  { path: 'charts', loadComponent: () => import('./feature/charts/charts.component').then((m) => m.ChartsComponent).catch(lazyLoadError) },
  {
    path: '',
    loadComponent: () => import('./components/post-layout/post-layout.component').then((m) => m.PostLayoutComponent).catch(lazyLoadError),
    children: [
      { path: '', loadComponent: () => import('./feature/home/home.component').then((m) => m.HomeComponent).catch(lazyLoadError) },
      { path: 'tags', loadComponent: () => import('./feature/tags/tags.component').then((m) => m.TagsComponent).catch(lazyLoadError) },
      { path: 'tags/:name', loadComponent: () => import('./feature/tag-detail/tag-detail.component').then((m) => m.TagDetailComponent).catch(lazyLoadError) },
      { path: ':name', loadComponent: () => import('./feature/post-detail/post-detail.component').then((m) => m.PostDetailComponent).catch(lazyLoadError) },
      { path: '**', redirectTo: '/' }
    ]
  },
  { path: '**', redirectTo: '/' }
];

